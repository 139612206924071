<template>
  <div>
    <ExtendedView
      :tabs="tabs"
      :selected-object="selectedPayment"
      @clearSelectedObject="selectedPayment = null"
    >
      <template v-slot:list="{ item }">
        <PaymentList
          v-if="item.key === 'approved'"
          :length="100"
          :step="100"
          :state="'success'"
          :fixed-filters="approvedPaymentsFilters"
          :load-items-callback="getPayments"
          @selectPayment="selectPayment"
          @loadItems="getPayments"
          :loading="loading"
        />
        <PaymentList
          v-if="item.key === 'declined'"
          :length="100"
          :step="100"
          :state="'failure'"
          :fixed-filters="declinedPaymentsFilters"
          :load-items-callback="getPayments"
          @selectPayment="selectPayment"
          @loadItems="getPayments"
          :loading="loading"
        />
        <PaymentList
          v-if="item.key === 'pending'"
          :length="100"
          :step="100"
          :state="'pending'"
          :load-items-callback="getPayments"
          :payments="pendingPayments"
          :fixed-filters="pendingPaymentsFilters"
          @selectPayment="selectPayment"
          @loadItems="getPayments"
          :loading="loading"
        />
      </template>

      <template slot="profile">
        <PaymentProfile
          v-if="selectedPayment"
          :payment="selectedPayment"
        />
      </template>
    </ExtendedView>
  </div>
</template>

<script>
  import ExtendedView from "@/components/common/ExtendedView"
  import PaymentList from "@/components/PaymentList"
  import PaymentProfile from "@/components/payment/PaymentProfile"

  export default {
    name: 'Payments',
    components: {
      PaymentList,
      PaymentProfile,
      ExtendedView
    },
    data: () => ({
      sort: 'desc',
      selectedPayment: null,
      loading: false,
      approvedPaymentsFilters: [
        {
          key: 'status',
          value: 'success',
          operator: 'equals'
        }
      ],
      declinedPaymentsFilters: [
        {
          key: 'status',
          value: 'failure',
          operator: 'equals'
        }
      ],
      pendingPaymentsFilters: [
        {
          key: 'status',
          value: 'pending',
          operator: 'equals'
        }
      ]
    }),
    methods: {
      exportPayments() {
        switch(this.selectedAppBarTab) {
          case 'approved':
            this.$store.commit('updateExportObjects', this.approvedPayments)
            break
          case 'pending':
            this.$store.commit('updateExportObjects', this.pendingPayments)
            break
          case 'declined':
            this.$store.commit('updateExportObjects', this.failedPayments)
            break
          default:
            this.$store.commit('updateExportObjects', this.payments)
            break
        }
      },
      selectPayment(payment) {
        this.selectedPayment = payment
        this.selectedPayment.paymentMethod = this.$store.state.paymentMethods.find(f => f.namespace == payment.namespace)
      },
      getPayments({limit, skip, conditions}) {
        return this.$store.dispatch('getPayments', {limit: limit, conditions})
      }
    },
    computed: {
      showBottomSheet: {
        get() {
          return this.selectedPayment !== null
        },
        set(value) {
          this.selectedPayment = null
        }
		  },
      tabs() {
        return [
          {
            title: this.$t('approved'),
            key: 'approved',
            badge: {
              color: 'green',
              content: this.approvedPaymentsCount
            }
          },
          {
            title: this.$t('pending'),
            key: 'pending',
            badge: {
              color: 'blue',
              content: this.pendingPaymentsCount,
            }
          },
          {
            title: this.$t('declined'),
            key: 'declined',
            badge: {
              color: 'red',
              content: this.failedPaymentsCount,
            }
          }
        ]
      },
      selectedAppBarTab() {
        return this.$store.state.selectedAppBarTab
      },
      payments() {
        return this.$store.state.payments
      },
      actionSuccess() {
        return this.$store.state.actionSuccess
      }
    },
    mounted() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
	  },
    created() {

      this.$store.commit('updateAppBarSearchChips', [
        {
          key: 'namespaces',
          label: this.$t('namespaces'),
          value: null,
          options: [
            {
              key: 'cash',
              label: this.$t('cash'),
            },
            {
              key: 'verifone',
              label: 'Verifone',
            }
          ]
        },
        {
          key: 'channels',
          label: this.$t('channels'),
          value: null,
          defaultValue: []
        }
      ])

      this.$store.commit('updateAppBarExport', {
        color: 'pink',
        callback: this.exportPayments
      })

      this.getPayments({limit: 1000})
    },
    watch: {
      actionSuccess(value) {
        console.log('fetch items within payments')
        if(value) {
          // setTimeout(() => {
          //   this.getPayments({ limit: 50, skip: 0, conditions: null })
          // }, 5000)
        }
      }
    }
  }
</script>
