<template>
  <div>
    <v-card
      v-if="payment !== null"
      class="rounded-card"
    >
      <v-toolbar flat>
        <v-toolbar-title
          class="font-weight-bold"
        >
          {{ $t('transaction') }} #{{ payment.uuid }}
        </v-toolbar-title>

        <v-tooltip
          top
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon
                v-if="payment.status==='success'"
                large
                color="green"
              >
                mdi-check-circle
              </v-icon>
              <v-icon
                v-if="payment.status==='failure'"
                large
                color="red"
              >
                mdi-alert-circle
              </v-icon>
              <v-icon
                v-if="payment.status==='pending'"
                large
                color="blue"
              >
                mdi-dots-horizontal-circle
              </v-icon>
            </v-btn>
          </template>
          <span v-if="payment.status==='success'">{{ $t('paymentApproved') }}</span>
          <span v-if="payment.status==='failure'">{{ $t('paymentDeclined') }}</span>
          <span v-if="payment.status==='pending'">{{ $t('paymentPending') }}</span>
        </v-tooltip>

        <v-card-actions v-if="payment.paymentMethod !== null && payment.paymentMethod !== undefined && payment.paymentMethod.supportedCallbacks !== null && payment.paymentMethod.supportedCallbacks !== undefined">
          <v-btn
            v-for="(callback, index) in payment.paymentMethod.supportedCallbacks"
            :key="index"
            @click="selectCallbackMethod(payment.paymentMethod.supportedCallbacks[index])"
            :disabled="updating"
            :loading="updating"
            text
            outline
          >
            {{ payment.paymentMethod.supportedCallbacks[index] }}
          </v-btn>
        </v-card-actions>
      </v-toolbar>
      <v-card-text
        class="mp-t-0"
      >
        <div
          class="inline"
        >
          {{ $t('created') }}: {{ payment.created | moment('DD/MM/YYYY, HH:mm:ss') }}
        </div>
        <div
          class="inline add"
        >
          {{ $t('changed') }}: {{ payment.changed | moment('DD/MM/YYYY, HH:mm:ss') }}
        </div>
      </v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('transactionID') }}</v-list-item-title>
            <v-list-item-subtitle>{{ payment.transactionId }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('paymentMethod') }}</v-list-item-title>
            <v-list-item-subtitle>{{ payment.namespace }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('message') }}</v-list-item-title>
            <v-list-item-subtitle>{{ payment.message }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('amount') }}</v-list-item-title>
            <v-list-item-subtitle>{{ payment.amount }} {{ payment.currency_code }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('status') }}</v-list-item-title>
            <v-list-item-subtitle>{{ payment.status }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="selectOrder">
          <v-list-item-content>
            <v-list-item-title>{{ $t('orderId') }}</v-list-item-title>
            <v-list-item-subtitle>{{ payment.orderId }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="payment.customer"
          @click="selectCustomer"
        >
          <v-list-item-content>
            <v-list-item-title>{{ $t('customer') }}</v-list-item-title>
            <v-list-item-subtitle>{{ payment.customer.address.name_line }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-actions v-if="false">
        <v-btn
          color="blue"
          text
          @click="voidDialog = true"
        >
          {{ $t('voidPayment') }}
        </v-btn>
        <v-btn
          color="red"
          text
          @click="cancelDialog = true"
        >
          {{ $t('cancelPayment') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      class="rounded-card"
    >
      <v-card-title>{{ $t('rawPaymentData') }}</v-card-title>
      <v-card-text v-if="payment.payload !== null && payment.payload !== undefined">
        <textarea
          v-model="payloadToString"
          rows="40"
          class="textArea"
          disabled="true"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
    name: 'PaymentProfile',
    props: {
      payment: {
        type: Object,
        default: () => null
      }
    },
    data () {
      return {
        confirmationDialog: false,
        updating: false,
        selectedCallback: "",
        paymentMethod: {}
      }
    },
    computed: {
      payloadToString() {
        if (this.payment && this.payment.payload) {
				  return JSON.stringify(this.payment.payload, null, "\t")
			  }
			return null
      }
    },
    methods: {
      selectCustomer() {
        this.$store.commit('updateContextOverlay', {
          'component':  'components/customer/CustomerProfile',
          'props': {
            'uuid': this.payment.customer.uuid
          }
        })
      },
      selectOrder() {
        this.$store.commit('updateContextOverlay', {
          'component':  'components/order/OrderProfile',
          'props': {
            'entangleKey': this.payment.entangleKey
          }
        })
      },
      handleCancelPaymentDialog() {
        this.$store.commit('updateGenericDialog', {
					component: 'components/dash/DashAlerts',
					props: {
						alerts: this.value.content.alerts
					}
				})
      },
      selectCallbackMethod(callbackMethod) {
        switch(callbackMethod) {
          case 'cancel':
            this.$store.commit('updateGenericDialog', {
              component: 'components/payment/CancelPayment',
              props: {
                payment: this.payment
              }
            })
            break
          case 'credit':
          this.$store.commit('updateGenericDialog', {
              component: 'components/payment/CreditPayment',
              props: {
                payment: this.payment
              }
            })
            break
        }
        //this.confirmationDialog = true
        //this.selectedCallback = callbackMethod
      },
      abortCallbackMethod() {
        this.confirmationDialog = false
        this.selectedCallback = ""
      },
      handleCallback() {
        this.updating = true
        switch (this.selectedCallback) {
          case "cancel":
            this.$store.dispatch('cancelPayment', this.payment.uuid).then(updatedPayment => {
              if (updatedPayment !== null && updatedPayment !== undefined) {
                this.$emit('selectPayment', updatedPayment)
                if(updatedPayment.status === this.payment.status) {
                  this.$store.commit('updateActionError', {
                    message: 'Could not cancel payment',
                    subMessage: updatedPayment.message
                  })
                } else {
                  this.$store.commit('updateActionSuccess', {
                    message: 'Success',
                    subMessage: 'Successfully cancelled payment'
                  })
                }
              } else {
                this.$store.commit('updateActionError', {
                  message: 'Could not cancel payment',
                  subMessage: 'null value'
                })
              }
            }).finally(() => {
              this.confirmationDialog = false
              this.selectedCallback = ""
              this.updating = false
            })
            break
          case "resolve":
            this.$store.dispatch('resolvePayment', this.payment.uuid).then(updatedPayment => {
              if (updatedPayment !== null && updatedPayment !== undefined) {
                this.$emit('selectPayment', updatedPayment)
                if(updatedPayment.status === this.payment.status) {
                  this.$store.commit('updateActionError', {
                    message: 'Could not resolve payment',
                    subMessage: updatedPayment.message
                  })
                } else {
                  this.$store.commit('updateActionSuccess', {
                    message: 'Success',
                    subMessage: 'Successfully resolved'
                  })
                }

              }
            }).finally(() => {
              // Need to update actionList payments here
              // this.$store.dispatch('getPayments', {limit: 1000})
              this.confirmationDialog = false
              this.selectedCallback = ""
              this.updating = false
            })
            break
          default:
            break
      }
    }
  }
}
</script>

<style scoped>

.inline {
	display: inline-block;
}

.br {
	border-right: 1px solid lightgray;
}

.bb {
	border-bottom: 1px solid lightgray;
}

.mp-b-0 {
	margin-bottom: 0;
	padding-bottom: 0;
}

.add {
	margin-left: 8%
}

.mp-t-0 {
	margin-top: 0;
	padding-top: 0;
}

.textArea {
	margin-left: 1%;
	width: 99%;
	height: 100%;
}

.rounded-card {
	margin-top: 1%;
	border-radius: 10px;
}

.center {
	align-items: center;
}

</style>
